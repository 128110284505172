<template>
  <div>
    <div class="md-title">Article Insights</div>
    <md-field>
      <label for="articleOptions">Article</label>
      <md-select v-model="selected" name="articleOptions" id="articleOptions" multiple md-dense>
        <md-option v-for="option in selectedOptions" v-bind:key="option.resourceId" :value="option.resourceId">
          {{
            option.resourceName
          }}
        </md-option>
      </md-select>
    </md-field>
    <div v-if="selected.length === 0" key="1" class="card-flex">
      Please select at least one article to start.
    </div>
    <div v-else-if="selected.length === 1" key="2">
      <div class="card-flex">
        <md-card class="card" md-with-hover>
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">
                {{
                  getCurrentArticles()
                      .length
                }}
              </div>
              <div class="md-subhead">
                <b>times</b> this article was read.
              </div>
            </md-card-header-text>
          </md-card-header>
        </md-card>
        <md-card class="card" md-with-hover>
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">
                {{
                  new Set(
                      getCurrentArticles()
                          .map(d => d.userId)
                  ).size
                }}
              </div>
              <div class="md-subhead">
                <b>unique user(s)</b> read this article.
              </div>
            </md-card-header-text>
          </md-card-header>
        </md-card>

        <md-card class="card" md-with-hover>
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">
                {{
                  timeFormatterHandler(
                      getCurrentArticles()
                          .map(d => d.userTime)
                          .reduce((a, b) => a + b, 0)
                  )
                }}
              </div>
              <div class="md-subhead">
                were spend <b>in total</b> on this article.
              </div>
            </md-card-header-text>
          </md-card-header>
        </md-card>
        <md-card class="card" md-with-hover>
          <md-card-header>
            <md-card-header-text>
              <div class="md-title">
                {{
                  timeFormatterHandler(
                      getCurrentArticles()
                          .map(d => d.userTime)
                          .reduce((a, b) => a + b, 0)
                      /
                      getCurrentArticles()
                          .length
                  )
                }}
              </div>
              <div class="md-subhead">
                were spend <b>on average</b> on this article.
              </div>
            </md-card-header-text>
          </md-card-header>
        </md-card>
      </div>
      <p>
        "{{
          getCurrentArticles()
              .map(d => d.resourceName)[0]
        }}"
        was written by
        <b>{{
            getCurrentArticles()
                .map(d => d.resourceInfo)[0]
          }}</b>.
      </p>
    </div>
    <div v-else key="3">
      <h3>How often was the Article read?</h3>
      <bar-chart
          style="height: 300px;"
          :chart-data="{
            labels: makeUniqueHandler(
                      getCurrentArticles()
                          .map(i => i.resourceName)
                    ),
            datasets: [
              {
                data: getCurrentArticles()
                  .reduce((acc, d) => {
                    if (!acc.find(v => v.name === d.resourceId))
                      acc.push({name: d.resourceId, value: 0})
                    acc.find(v => v.name === d.resourceId).value += 1
                    return acc
                  }, [])
                  .map(d => d.value),
                label: 'Total',
                backgroundColor: '#444',
              },
              {
                data: getCurrentArticles()
                  .reduce((acc, d) => {
                    if (!acc.find(v => v.name === d.resourceId))
                      acc.push({name: d.resourceId, value: 0, users:[]})
                    if(acc.find(v => v.name === d.resourceId).users.includes(d.userId))
                      return acc
                    acc.find(v => v.name === d.resourceId).value += 1
                    acc.find(v => v.name === d.resourceId).users.push(d.userId)
                    return acc
                  }, [])
                  .map(d => d.value),
                label: 'Unique',
                backgroundColor: '#888',
              }
            ]
          }"
          :options="chartOptions"/>

      <h3>How much time was spend?</h3>
      <bar-chart
          style="height: 300px;"
          :chart-data="{
            labels: makeUniqueHandler(
                      getCurrentArticles()
                          .map(i => i.resourceName)
                    ),
            datasets: [
              {
                data: getCurrentArticles()
                  .reduce((acc, d) => {
                    if (!acc.find(v => v.name === d.resourceId))
                      acc.push({name: d.resourceId, value: 0})
                    acc.find(v => v.name === d.resourceId).value += (d.userTime / 60)
                    return acc
                  }, [])
                  .map(d => d.value.toFixed(2)),
                label: 'Total',
                backgroundColor: '#444',
              },
              {
                data: getCurrentArticles()
                  .reduce((acc, d) => {
                    if (!acc.find(v => v.name === d.resourceId))
                      acc.push({name: d.resourceId, value: 0, users:[]})
                    acc.find(v => v.name === d.resourceId).value += (d.userTime / 60)
                    acc.find(v => v.name === d.resourceId).users.push(d.userId)
                    return acc
                  }, [])
                  .map(d => (d.value/d.users.length).toFixed(2)),
                label: 'Average',
                backgroundColor: '#888',
              }
            ]
          }"
          :options="chartOptionsTime"/>
    </div>
    <div style="width: 100%; text-align: right; margin-top: 2em">
      <p @click="deleteAll">reset data</p>
    </div>
  </div>
</template>
<script>
import config from '@/config'
import BarChart from '@/components/charts/bar'

import {makeUnique} from '@/utils/array'
import {timeFormatter} from "@/utils/time";
import axios from "axios";

export default {
  name: 'Referral',
  data: function () {
    return {
      selected: [],
      selectedOptions: [],

      dataRaw: [],
      chartData: null,
      chartOptions: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        },
        responsive: true,
        maintainAspectRatio: false
      },
      chartOptionsTime: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: 'minutes'
            }
          }],
        },
        responsive: true,
        maintainAspectRatio: false
      },

      debug: null
    }
  },
  methods: {
    makeUniqueHandler: function (a) {
      return makeUnique(a)
    },
    timeFormatterHandler: function (a) {
      return timeFormatter(a)
    },
    getCurrentArticles() {
      return this.dataRaw
          .filter(d => this.selected.includes(d.resourceId))
    },
    getSelectedOptions: function (data) {
      let optionHelper = []
      return data
          .map(d =>
              ({
                resourceId: d.resourceId,
                resourceName: d.resourceName
              })
          )
          .filter(d => {
            if (optionHelper.includes(d.resourceId)) // duplicate
              return false

            optionHelper.push(d.resourceId)
            return true
          });
    },
    deleteAll() {
      const p = window.prompt(
          "All collected time data will be deleted.\nIf you want to do that, type 'Yes'.",
          "No"
      );
      if (p === 'Yes') {
        axios.delete(config.LOGGER + '/resources')
            .then(() => alert('Everything is gone.'))
            .catch(e => alert('Something went wrong:\n' + e))
            .finally(() => this.loadData())
      }
    },
    loadData: async function () {
      this.dataRaw = (await axios.get(config.LOGGER + '/resources')).data

      // create options
      this.selectedOptions = this.getSelectedOptions(this.dataRaw)

      // pre-select
      this.selected = this.selectedOptions.slice(0, 3).map(d => d.resourceId)
    },
  },
  mounted() {
    this.loadData();
  },
  components: {
    BarChart
  }
}
</script>
<style scoped>
.card-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.card {
  min-width: 250px;
  width: 45%;
  margin-bottom: 20px;
}
</style>